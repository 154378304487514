b {
  /* used for event dates/times */
  margin-right: 3px;
}

.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.demo-app-sidebar {
  width: 300px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}

.demo-app-sidebar-section {
  padding: 2em;
}

.demo-app-main {
  flex-grow: 1;
  /* padding: 3em; */
}

.fc {
  /* the calendar root */
  /* max-width: 1100px; */
  margin: 0 auto;
}

.fc .fc-timegrid-slot {
  height: 3em;
}

.fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
  text-transform: capitalize;
}

@media only screen and (max-width: 600px) {
  .fc .fc-toolbar.fc-header-toolbar {
    flex-direction: column;
    gap: 8px;
  }

  .fc .fc-toolbar-title {
    font-size: 24px;
  }
}
